import React from "react";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Projects from "./pages/Projects/Projects";
import Contact from "./pages/Contact/Contact";

const Page = {
  Home: {
    name: "HOME",
    path: "/",
    element: React.createElement(Home),
  },
  About: {
    name: "ABOUT",
    path: "/about",
    element: React.createElement(About),
  },
  Project: {
    name: "PROJECTS",
    path: "/projects",
    element: React.createElement(Projects),
  },
  Contact: {
    name: "CONTACT",
    path: "/contact",
    element: React.createElement(Contact),
  }
} as const;

// eslint-disable-next-line 
type Page = (typeof Page)[keyof typeof Page];

// this exports the type not the const object
export default Page;