import { Canvas, useFrame } from "@react-three/fiber";
import { Mesh } from "three";
import { useEffect, useRef, useState, useContext } from "react";
import {
    MeshWobbleMaterial,

} from "@react-three/drei";
import "./styles.css";

import {

} from "framer-motion";


import {

    EffectComposer,

} from "@react-three/postprocessing";

import useMousePosition from "../../hooks/useMousePosition";

import { PageContext } from "../../contexts/pageContext";

type RingProps = {
    id: number;
    position?: [number, number, number];
    radius?: number;
    tube?: number;
    radialSegments?: number;
    tubularSegments?: number;
    arc?: number;
    rotation: number;

    desiredPosition?: [number, number, number];
    desiredRotation: [number, number, number];
};

const Ring = (props: RingProps) => {
    const boxRef = useRef<Mesh>(null);

    const mousePosition = useMousePosition();

    useFrame(() => {
        // if (boxRef.current != null) {
        //     boxRef.current.rotation.x = props.rotation;
        //     boxRef.current.rotation.y = props.rotation;
        // }

        if (boxRef.current != null && props.desiredPosition != null) {
            let x = boxRef.current.position.x;
            let y = boxRef.current.position.y;
            let z = boxRef.current.position.z;

            let dx = props.desiredPosition[0] - x;
            let dy = props.desiredPosition[1] - y;
            let dz = props.desiredPosition[2] - z;

            let speed = 0.015;

            boxRef.current.position.x += dx * speed;
            boxRef.current.position.y += dy * speed;
            boxRef.current.position.z += dz * speed;

            //console.log(props.desiredPosition);
        }

        if (boxRef.current != null && props.desiredRotation != null) {
            let x = boxRef.current.rotation.x;
            let y = boxRef.current.rotation.y;
            let z = boxRef.current.rotation.z;

            let offsetX = ((mousePosition.x - window.screen.width*0.5) / window.screen.width) * props.id * 0.25 * 1;
            let offsetY = ((mousePosition.y - window.screen.height*0.5) / window.screen.height) * props.id * 0.25 * 1;

            let dx = props.desiredRotation[0] + offsetY - x;
            let dy = props.desiredRotation[1] + offsetX - y;
            let dz = props.desiredRotation[2] - z;

            let speed = 0.05;   

            boxRef.current.rotation.x += dx * speed
            boxRef.current.rotation.y += dy * speed 
            boxRef.current.rotation.z += dz * speed;

            //console.log(props.desiredPosition);
        }
    });

    return (
        <mesh ref={boxRef} position={props.position}>
            <torusGeometry
                args={[
                    props.radius,
                    props.tube,
                    props.radialSegments,
                    props.tubularSegments,
                ]}
            />
            <MeshWobbleMaterial color={"white"} speed={0.2} factor={2} />
        </mesh>
    );
};

const Three = () => {
    // const { scrollY } = useScroll();

    const pageContext = useContext(PageContext);

    const pos :[number, number, number]=  [0, 0, 6];
    const [desiredPosition, setDesiredPosition] = useState<
        [number, number, number]
    >([0, 0, 0]);


    const [desiredRotation] = useState<
        [number, number, number]
    >([0, 0, 0]);

    // const mouseMoving = (e: MouseEvent) => {
    //     setDesiredRotation([e.clientX / 100, e.clientY / 100, e.clientX / 100]);
    //     //console.log("moving");
    // };
    // window.addEventListener("mousemove", mouseMoving);

    const [rings, setRings] = useState([
        <Ring
        id={0}
        key={0}
            position={[0, 0, 0]}
            desiredPosition={pos}
            desiredRotation={[0, 0, 0]}
            radius={0}
            tube={0.02}
            radialSegments={30}
            tubularSegments={10}
            rotation={0}
        />,
    ]);

    const initRings = (lim: number) => {
        let rings = [];
        for (let i = 0; i < lim; i++) {
            rings.push(
                <Ring
                    id={i}
                    key={i}
                    position={pos}
                    desiredPosition={desiredPosition}
                    desiredRotation={desiredRotation}
                    radius={1 + 0.9 * i}
                    tube={0.1}
                    radialSegments={10}
                    tubularSegments={200}
                    rotation={i}
                />
            );
        }
        return rings;
    };

    useEffect(() => {
        let rings = initRings(5);
        setRings(rings);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        //console.log("Desired Position: ", desiredPosition);
        let rings = initRings(5);
        setRings(rings);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [desiredPosition, desiredRotation]);


    useEffect(() => {
        console.log("Page State: ", pageContext.pageState);

        if (pageContext.pageState === "home") {
            setDesiredPosition([0, 0, -4]);
        }

        if (pageContext.pageState === "about") {
            setDesiredPosition([3, 0, 2]);
        } else if (pageContext.pageState === "projects") {
            setDesiredPosition([0, 0, 4]);
        } else if (pageContext.pageState === "contact") {
            setDesiredPosition([-3, 0, 2]);
        }
    }, [pageContext.pageState]);


    return (
        <div className={"main"}>
            <Canvas>
                <ambientLight />
                <directionalLight position={[0, 0, 10]} intensity={1} />

                {rings.map((ring) => {
                    return ring;
                })}

                <EffectComposer>
                    {/* <DepthOfField
                        focusDistance={0}
                        focalLength={0.02}
                        bokehScale={2}
                        height={480}
                    /> */}
                    {/* <Bloom
                        luminanceThreshold={0}
                        luminanceSmoothing={0.9}
                        height={300}
                    /> */}
                    {/* <Noise opacity={0.05} /> 
                    <Vignette eskil={false} offset={0} darkness={1.25} /> */}
                    {/* <ChromaticAberration
                        blendFunction={BlendFunction.NORMAL}

                        // @ts-ignore
                        offset={[0.0004, 0.0004]}
                    /> */}
                </EffectComposer>

                {/* <Sphere /> */}
            </Canvas>

            {/* <>{window.removeEventListener("mousemove", mouseMoving)}</> */}
        </div>
    );
};

export default Three;
