import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { PageContext, pageState } from "../../contexts/pageContext";


import FadeLayout from "../../components/util/FadeLayout";



import "./styles.css";

const Home = () => {
    
    const [show, setShow] = useState(true);

    //const opacity = useSpring(0, {stiffness: 100, damping: 20});

    

    const navigate = useNavigate();

    const { pageState, updatePageState } = useContext(PageContext);

    const handleClick = async (loc: pageState) => {
        console.log("Clicked");
        updatePageState(loc);

        // await delay(750);
        navigate(`/${loc}`);
    };

    useEffect(() => {
        updatePageState("home");
    });

    useEffect(() => {
        if (pageState !== "home") {
            console.log("FADING");
            setShow(false);
        }
    }, [pageState]);

    useEffect(() => {}, [show]);

    return (
        <FadeLayout>
        <div className={"home"}>
            <div>
                <div className={"name"}>
                    <span>M&nbsp;A&nbsp;R&nbsp;C&nbsp;U&nbsp;S</span>&nbsp;&nbsp;&nbsp;<span>P&nbsp;H&nbsp;I&nbsp;L&nbsp;L&nbsp;I&nbsp;P&nbsp;S&nbsp;W&nbsp;A&nbsp;T&nbsp;S&nbsp;O&nbsp;N</span>
                </div>

                <div className={"navbar"}>
                    <div className={"item"}>
                        <button
                            className="item-button"
                            onClick={() => {
                                handleClick("about");
                            }}
                        >
                            A B O U T
                        </button>
                    </div>
                    <div className={"item"}>
                        <button
                            className="item-button"
                            onClick={() => {
                                handleClick("projects");
                            }}
                        >
                            P R O J E C T S
                        </button>
                    </div>
                    <div className={"item"}>
                        <button
                            className="item-button"
                            onClick={() => {
                                handleClick("contact");
                            }}
                        >
                            C O N T A C T
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </FadeLayout>
    );
};

export default Home;
