import "./styles.css";
import AnimatedLayout from "../../components/util/AnimatedLayout";
import { useContext, useEffect, useRef } from "react";
import { PageContext } from "../../contexts/pageContext";
import { MouseEvent } from "react";

import { motion, useMotionValue, useTransform, useSpring } from "framer-motion";

type Props = {
    link?: string;
    path: string;
    picturePath: string;
};

const ProjectCard = ({ link, path, picturePath }: Props) => {
    const cardRef = useRef<HTMLDivElement>(null);

    const stiffness = 200;
    const damping = 200;

    const cardX = useMotionValue(0);
    const cardY = useMotionValue(0);
    const rotateX = useSpring(useTransform(cardY, [-300, 300], [10, -10]), {
        stiffness: stiffness,
        damping: damping,
    }); // Reversed values
    const rotateY = useSpring(useTransform(cardX, [-300, 300], [-10, 10]), {
        stiffness: stiffness,
        damping: damping,
    }); // Reversed values
    const cardRotateX = useSpring(useTransform(cardY, [-300, 300], [15, -15]), {
        stiffness: stiffness,
        damping: damping,
    }); // Adjusted rotation values
    const cardRotateY = useSpring(useTransform(cardX, [-300, 300], [-15, 15]), {
        stiffness: stiffness,
        damping: damping,
    }); // Adjusted rotation values

    const handleMouseMove = (event: MouseEvent) => {
        if (!cardRef.current) {
            return;
        }

        const offsetX =
            event.clientX -
            cardRef.current!.offsetLeft -
            cardRef.current!.offsetWidth / 2;
        const offsetY =
            event.clientY -
            cardRef.current!.offsetTop -
            cardRef.current!.offsetHeight / 2;

        cardX.set(offsetX);
        cardY.set(offsetY);
    };

    const handleMouseLeave = () => {
        cardX.set(0);
        cardY.set(0);
    };

    return (
        <section className="project">
            <motion.div
                ref={cardRef}
                style={{
                    transformStyle: "preserve-3d",
                    perspective: 800,
                    rotateX,
                    rotateY,
                }}
                transition={{ velocity: 0 }}
            >
                <motion.div
                    // className="vid-div"
                    style={{
                        boxShadow: "0px 0px 20px -7px rgba(0,0,0,0.45)",
                        transformStyle: "preserve-3d",
                        perspective: 800, // Set perspective on the card
                        rotateX: cardRotateX,
                        rotateY: cardRotateY,
                    }}
                    transition={{ velocity: 0 }}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                >
                    {/* <video src={} controls width="640" height="360"></video> */}
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        <img
                            src={picturePath}
                            alt="project"
                            className={"project-image"}
                        />
                    </a>
                </motion.div>
            </motion.div>
        </section>
    );
};

const Projects = () => {
    const { updatePageState } = useContext(PageContext);

    const variants = {
        hidden: { opacity: 0, x: 0, y: 0 },
        enter: { opacity: 1, x: 0, y: 0 },
        exit: { opacity: 0, x: 0, y: 0 },
    };

    useEffect(() => {
        updatePageState("projects");
    });

    return (
        <AnimatedLayout variants={variants} duration={0.5}>
            <main className="projects">
                <div className="projects-title">P R O J E C T S / W O R K </div>

                <section className="projects-container">
                    {/* <div className="project">
                        <div className="project-title">shirokuro</div>
                        <div className="project-text">
                            Collection of the some of my favorite p5js art I've
                            done over the years.
                        </div>
                        <div className="vid-div">
                            <video
                                src={"/static/shiro.mp4"}
                                controls
                                width="640"
                                height="360"
                            ></video>
                        </div>
                    </div> */}

                    <ProjectCard
                        link={"https://shirokuro-d65a6.web.app/"}
                        path={"/projects/1"}
                        picturePath={"/static/shiro.png"}
                    />

                    <ProjectCard
                        path={"/projects/2"}
                        picturePath={"/static/cm.png"}
                    />
                </section>
            </main>
        </AnimatedLayout>
    );
};

export default Projects;
