import React from "react";
import Three from "./components/Three/Three";
import Navbar from "./components/navbar/Navbar";

import "./App.css";


import { PageProvider } from "./contexts/pageContext";

import AnimatedOutlet from "./components/util/AnimatedOutlet";

function App() {
    return (
        <>
            <PageProvider>
                    <Three />
                    <Navbar />
                    <AnimatedOutlet />
            </PageProvider>
        </>
    );
}

export default App;
