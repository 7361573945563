import {createContext, useState} from 'react';

export type pageState = "" | "home" | "about" | "projects" | "contact";
export type updatePageState = (newPageState : pageState) => void;

export const PageContext = createContext<{
    pageState : pageState;
    updatePageState : updatePageState;
}>({
    pageState : "home",
    updatePageState : () => {},
});

export const PageProvider = (props : {children : React.ReactNode}) => {
    const [pageState, setPageState] = useState<pageState>("home");

    const updatePageState = (newPageState : pageState) => {
        setPageState(newPageState);
    }

    return (
        <PageContext.Provider value={{pageState, updatePageState}}>
            {props.children}
        </PageContext.Provider>
    );
}