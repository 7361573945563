import "./styles.css";
import { useEffect, useContext } from "react";
import { PageContext } from "../../contexts/pageContext";
import FadeLayout from "../../components/util/FadeLayout";

const Contact = () => {
    const { pageState, updatePageState } = useContext(PageContext);

    useEffect(() => {
        updatePageState("contact");
    });

    useEffect(() => {
        if (pageState !== "contact") {
            console.log("WHY");
        }
    }, [pageState]);

    return (
        <FadeLayout>
            {/* <Navbar /> */}
            <div className={"contact"}>
                <div className="contact-container">
                    {/* <div className="contact-title">Contact me</div> */}
                    <div className={"contact-text"}>
                        <a
                            href="https://www.linkedin.com/in/marcus-phillipswatson-485157236/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            LinkedIn
                        </a>
                    </div>
                    <div className={"contact-text"}>
                        <a
                            href="https://github.com/sho04"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Github
                        </a>
                    </div>
                    <div className={"contact-text"}>
                        <a
                            href="mailto:marcustreyzoe@gmail.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Email
                        </a>
                    </div>
                </div>
            </div>
        </FadeLayout>
    );
};

export default Contact;
