import "./styles.css";
import { useEffect, useContext } from "react";
import { PageContext } from "../../contexts/pageContext";
import FadeLayout from "../../components/util/FadeLayout";


const About = () => {
    const { pageState, updatePageState } = useContext(PageContext);

    useEffect(() => {
        updatePageState("about");
    });

    useEffect(() => {
        if (pageState !== "about") {
            console.log("WHY");
        }
    }, [pageState]);

    return (
        <FadeLayout>
            {/* <Navbar /> */}
            <div className={"about"}>
                <div className="about-container">
                    <div className="about-title">About Me</div>
                    <div className={"about-text"}>
                        Yo, i'm Marcus and i'm currently a 3rd year Computer Science student studying at the
                        University of Central Florida.
                        <br />
                        <br />
                        I was previously a Software Engineer at WellBilt Industries.
                        <br />
                        <br />
                        I mostly just enjoy creating stuff that I find cool.
                        <br />
                        <br />I usually use typescript, react, and node.js to
                        create whatever that may be.
                    </div>
                </div>
            </div>
        </FadeLayout>
    );
};

export default About;
