import "./styles.css";
import { useContext, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageContext, pageState } from "../../contexts/pageContext";
import { motion, useScroll, useMotionValueEvent } from "framer-motion";

const Navbar = () => {
    const navigate = useNavigate();
    const { scrollY } = useScroll();
    const navRef = useRef<HTMLDivElement>(null);

    const { pageState, updatePageState } = useContext(PageContext);

    //const delay = (ms : number) => new Promise(res => setTimeout(res, ms));

    const handleClick = async (loc: pageState) => {
        updatePageState(loc);
        // await delay(1000);
        navigate(`/${loc}`);
    };

    useEffect(() => {
        if (pageState === "home") {
            if (navRef.current != null) {
                navRef.current.style.opacity = "0";
            }
        } else {
            if (navRef.current != null) {
                navRef.current.style.opacity = "1";
            }
        }
    }, [pageState]);

    useMotionValueEvent(scrollY, "change",(latest) => {
        if (latest > 10) {
            if (navRef.current != null) {
                navRef.current.style.opacity = "0";
                console.log("hide")
            }
        } else {
            if (navRef.current != null) {
                navRef.current.style.opacity = "1";
            }
        }
    });

    return (
        <motion.div className={"navbar-2"} ref={navRef}>
            <div
                className={"navbar-2-item"}
                onClick={() => {
                    handleClick("");
                }}
            >
                <span>H&nbsp;O&nbsp;M&nbsp;E</span>
            </div>
            <div
                className={"navbar-2-item"}
                onClick={() => {
                    handleClick("about");
                }}
            >
                <span>A&nbsp;B&nbsp;O&nbsp;U&nbsp;T</span>
            </div>
            <div
                className={"navbar-2-item"}
                onClick={() => {
                    handleClick("projects");
                }}
            >
                P&nbsp;R&nbsp;O&nbsp;J&nbsp;E&nbsp;C&nbsp;T&nbsp;S
            </div>
            <div
                className={"navbar-2-item"}
                onClick={() => {
                    handleClick("contact");
                }}
            >
                C&nbsp;O&nbsp;N&nbsp;T&nbsp;A&nbsp;C&nbsp;T
            </div>
        </motion.div>
    );
};

export default Navbar;
