import React, { ReactNode } from "react";
import { motion } from "framer-motion";

type Props = {
    children: ReactNode;

    variants: {
        enter: { opacity: number; x: number; y: number };
        exit: { opacity: number; x: number; y: number };
        hidden: { opacity: number; x: number; y: number };
    };

    duration: number;
};

// I want a fade in bottom-up - fade out top-down animation
// so these are my variants

const AnimatedLayout = ({ children, variants, duration }: Props): React.JSX.Element => {
    return (
        <motion.div
            initial="hidden"
            animate="enter"
            exit="exit"
            variants={variants}
            transition={{ duration: duration, type: "easeInOut" }}
            className="relative"
        >
            {children}
        </motion.div>
    );
};

export default AnimatedLayout;
